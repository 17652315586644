<template src="./App.html"></template>

<script>
export default {
  name: "app",
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css";
@import "../styles/style.css";
</style>
