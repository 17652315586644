<template src="./quiz-results.html"></template>
<script>
import quizService from "../../../services/quiz-service";
import appHeader from "../../../../shared-module/components/app-header/app-header.vue";
import { indexOf } from "lodash";
var chevronListBox;
var feedback = false;
export default {
  name: "app-quiz-results",
  components: {
    "app-header": appHeader,
  },
  data: () => {
    return {
      resultId: "",
      searchResults:[],
      readMoreStatus: {},
      selectedText:'',
      isModalOpen:false,
      selectedQuestionId:'',
      uploadImgPopUp: false,
      selectedImage: '',
      forAnswer:false,
    };
  },
  mounted() {
    if (this.$route.query.resultId) {
      this.resultId = this.$route.query.resultId;
      this.getResultsByResultId();
      this.initializeReadMoreStatus();
    }
  },
  methods: {
    async getResultsByResultId() {
      try {
        let response = await quizService.getResultsByResultIdForDaq(this.resultId);
        this.searchResults = response.data.data.result;
      } catch (error) {
        console.error(error);
        this.$toast.error("Results Not Found");
      }
    },
    initializeReadMoreStatus() {
      // Set default status for each result to false
      this.searchResults.questions[0].questions.forEach((result, index) => {
        this.$set(this.readMoreStatus, index, false); // false indicates to show truncated text
      });
    },
    isTextLong(text, index) {
      return text && text.length > 30;
    },

    truncateText(text) {
      // Adjust the character limit as neededo
      console.log("i am called----text--",text.length);
      const maxLength = 25;
      return text?.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
    openModal(text,quesId,forAnswer=false) {
      this.forAnswer=forAnswer;
      this.selectedText = text;
      this.isModalOpen = true;
      this.selectedQuestionId=quesId;
    },
    closeModal() {
      this.forAnswer=false;
      this.isModalOpen = false;
      this.selectedText = '';
      this.selectedQuestionId='';
    },
    async updateText(){
      console.log("i am called to update----")
      try {
        let response = await quizService.updateResponse(this.searchResults.questions[0]._id,this.selectedQuestionId,this.selectedText);
        console.log(response);
        if(response.data.success)
          {
            this.$toast.success("Response updated");
          }
         this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.error("unexpected error occured");
      }
    },

    backToMainResults () {
      console.log('working');
      window.history.back();
      console.log('working funtion');
    },
    showImage(image) {
      this.selectedImage = image;
      this.uploadImgPopUp = true;
  },
  },
};
</script>

<style src="./quiz-results.css" scoped></style>
