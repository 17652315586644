const axios = require("axios");

export const quizBackendService = axios.create({
  baseURL: process.env.VUE_APP_QUIZ_SERVICE_URL,
});

export const mainBackend = axios.create({
  baseURL: process.env.VUE_APP_MAIN_BACKEND_URL,
});

export const authBackend = axios.create({
  baseURL: process.env.VUE_APP_AUTH_SERVICE_URL,
});
