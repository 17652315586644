<template src="./create-popup-quiz.html"> </template>

<script>
import quizQuestions from "../../edit-quiz-questions/edit-quiz-single-question/edit-quiz-single-question";
import videoService from "../../../services/video-service";
import tagService from "../../../services/tag-service";
import quizService from "../../../services/quiz-service";
import storageService from "../../../services/storage-service";
import sessionService from "../../../services/session-service";
import _ from "lodash";

export default {
  name: "app-create-popup-quiz",
  data: function() {
    return {
      maxVal: 0,
      minVal: 0,
      currentVideoPoint: 0,
      chevronListBox: false,
      vidPoints:[],
      player: "",
      videoLoaded: true,
      btnPlayPause: "",
      popupType: 0,
      PopUp: false,
      percentage: 0,
      progressBar: "",
      dataValidated: false,
      errors: [],
      ommissions: [],
      is_skippable: false,
      skippable: false,
      videoPoints: [],
      videoPoint: {},
      componentKey: 0,
      range: null,
      bubble: null,
      lab: null,
      right: null,
      tags: [],
      question: {
        quiz_id: "",
        question: "",
        equation: "",
        image_url: "",
        topic: "",
        tags: [],
        related_video_id: "",
        related_video: { name: "Link to video" },
        weightage: 0,
        self_assigned_weightage: false,
        solution: "",
        solution_equation: "",
        solution_image_url: "",
        options: [
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
        ],
        // checkedValue: ""
      },
      // selectQuizType: true
    };
  },
  props: {
    course: {
      type: Object,
    },
    module: {
      type: Object,
    },
    chapter: {
      type: Object,
    },
    video: {
      type: Object,
    },
    instructorId: {
      type: String,
    },
    previousQuizId: {
      type: String,
    },
    questions: {
      type: Array,
    },
    quiz: { type: Object },
    session: { type: Object },
  },
  components: {
    "app-edit-quiz-single-questions": quizQuestions,
  },
  mounted() {
    this.range = document.getElementById("range");
    this.bubble = document.getElementById("bubble");
    this.lab = document.getElementById("lab");
    this.right = document.getElementById("right");
    this.questions = this.questions ? this.questions : [];
    this.storageId = `questions-${this.quiz._id}-${this.instructorId}`;
    this.initializePlayer();
    this.getVideoPoints();
    this.getAllTags();
    this.updateLocalStorage();
    this.selectQuizType(false);
    
  },
  methods: {
    updateLocalStorage() {
      if (this.questions) {
        if (!this.questions.length) {
          this.questions = [];
          this.question.quiz_id = this.quiz._id;
          this.questions.push(_.cloneDeep(this.question));
        } else {
          this.questions.forEach((elm) => {
            elm.quiz_id = this.quiz._id;
            if (elm._id) delete elm._id;
          });
          this.chevronListBox = true;
        }
        storageService.set(this.storageId, {
          quiz: this.quiz,
          questions: this.questions,
        });
      } else {
        const { questions, quiz } = storageService.get(this.storageId);
        this.questions = questions;
        this.quiz = quiz;
      }
      if (this.quiz && this.quiz.is_skippable) {
        this.skippable = this.quiz.is_skippable;
      }
      if (this.questions.length && this.chevronListBox) {
        this.validateQuestions();
      }
    },
    validateQuestions() {
      try {
        let errors = [];
        let ommissions = [];
        let questions = [];
        let data = storageService.get(this.storageId);
        if (data && data.questions) {
          questions = data.questions;
          questions.forEach((question, index) => {
            if (
              question.equation === undefined ||
              question.equation === "" ||
              question.equation === " "||
              !question.equation.replace(`\\`, "").trim()
            ) {
              if (
                question.question === undefined ||
                question.question === "" ||
                question.question === " "
              ) {
                this.questions[
                  index
                ].error = `Question text can not be empty at Question # ${index +
                  1}`;
                errors.push(this.questions[index].error);
              }
            }
            if (question.options && question.options.length) {
              let oneOptionIsCorrect = 0;
              question.options.forEach((option, indx) => {
                if (
                  option.equation === undefined ||
                  option.equation === "" ||
                  option.equation === " "||
                   !option.equation.replace(`\\`, "").trim()
                ) {
                  if (
                    option.option === undefined ||
                    option.option === "" ||
                    option.option === " "
                  ) {
                    this.questions[
                      index
                    ].error = `Option text can not be empty at option # ${indx} at Question # ${index +
                      1}`;
                    errors.push(this.questions[index].error);
                  }
                }
                if (option.is_correct === true) {
                  oneOptionIsCorrect += 1;
                }
                if (indx + 1 === question.options.length) {
                  if (oneOptionIsCorrect === 0) {
                    this.questions[
                      index
                    ].error = `Correct option is required at Question # ${index +
                      1}`;
                    errors.push(this.questions[index].error);
                  } else if (oneOptionIsCorrect > 1) {
                    this.questions[
                      index
                    ].error = `Only one option should be marked as true at Question # ${index +
                      1}`;
                    errors.push(this.questions[index].error);
                  }
                }
              });
            }
            if (question.tags && !question.tags.length) {
              this.questions[
                index
              ].ommission = `Minimum one tag should be added at Question # ${index}`;
              ommissions.push(this.questions[index].ommission);
            }
          });
          this.errors = errors;
          this.ommissions = ommissions;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getAllTags() {
      const response = await tagService.getAllTags();
      if (response) {
        this.tags = [];
        response.data.data.tag.forEach((elm) => {
          this.tags.push({ id: elm._id, text: elm.tag_name });
        });
      }
    },
    async saveVideoPoints(isPublish) {
   //if (this.$parent.editMode) {
   if (this.videoPoint.id) {
    this.videoPoints.forEach((element, index) => {
     if (element.id === this.videoPoint.id) {
      this.videoPoints[index].time = this.range.value;
      this.videoPoints[index].percentage = (100 / this.player.duration) * this.range.value;
      this.videoPoints[index].data = `Quiz - ${this.quiz.name}`;
      this.videoPoints[index].link_text = `${this.session._id}`;
      this.videoPoints[index].disabled = !isPublish;
     }
    });
   }
   //}
   else {
    this.videoPoints.push({
     time: this.range.value,
     percentage: this.percentage,
     data: `Quiz - ${this.quiz.name}`,
     isLink: true,
     link_text: `${this.session._id}`,
     new: true,
     disabled: !isPublish,
    });
   }
   const data = {
    video_id: this.video.id,
    module_id: this.chapter.id ? this.chapter.id : this.module.id,
    points: this.videoPoints,
   };
   let response = await videoService.saveVideoPoints(data);
   if (response) {
    if (response.data.success) {
     this.$toast.success("Published Successfully");  
       setTimeout(function(){ 
          window.location.reload();
      }, 2000);
     this.$parent.backMethod();
    }
   }
  },
    setUp() {
      this.range.max = this.maxVal;
      this.range.value = this.currentVideoPoint;
      this.range.addEventListener("input", () => {
        this.setBubble(this.range, this.bubble, this.maxVal, this.minVal);
        let rvalue = this.range.value / this.maxVal;
        rvalue = this.player.duration * rvalue;
        this.player.currentTime = rvalue;
      });
      this.setBubble(this.range, this.bubble, this.maxVal, this.minVal);
      this.lab.innerHTML = this.sToTime(this.maxVal * 1000);
      this.right.innerHTML = this.sToTime(this.maxVal * 1000);
    },
    changeButtonType(btn, value) {
      btn.title = value;
      btn.innerHTML = value;
      btn.className = value;
    },
    async getVideoPoints() {
      try {
        this.videoLoaded = false;
        let response = await videoService.getVideoPoints(this.video.id);
        this.vidPoints = response.data.points; 
        if (response) {
          this.player.src = response.data.url;
          this.videoPoints = response.data.points;
          if (this.$parent.editMode && this.videoPoints.length) {
            this.videoPoint = this.videoPoints.find(
              (element) => element.link_text === this.session._id
            );
          }
          if (!this.videoPoint || !this.videoPoint.time) {
            this.videoPoint = {
              time: this.range.value,
              percentage: this.percentage,
              data: `Quiz - ${this.quiz.name}`,
              isLink: true,
              link_text: `${this.session._id}`,
              new: true,
            };
          }
          this.player.load();
          this.player.onloadeddata = () => {
            this.videoLoaded = true;
            this.maxVal = parseInt(this.player.duration);
            this.currentVideoPoint = parseInt(this.videoPoint.time);
            this.setUp();
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    playPauseVideo() {
      if (this.player.paused || this.player.ended) {
        // Change the button to a pause button
        this.player.play();
      } else {
        // Change the button to a play button
        this.player.pause();
      }
    },
    initializePlayer() {
      this.player = document.getElementById("video-element");
      this.btnPlayPause = document.getElementById("btnPlayPause");
      this.progressBar = document.getElementById("progress-bar");
      this.player.addEventListener("timeupdate", this.updateProgressBar, false);
      // Add a listener for the play and pause events so the buttons state can be updated
      this.player.addEventListener(
        "play",
        () => {
          // Change the button to be a pause button
        },
        false
      );
      this.player.addEventListener(
        "pause",
        () => {
          // Change the button to be a play button
        },
        false
      );
      this.player.addEventListener(
        "ended",
        () => {
          this.pause();
        },
        false
      );
      this.progressBar.addEventListener("click", this.seek);
    },
    updateProgressBar() {
      if (this.range) {
        this.range.value = this.player.currentTime;
        this.setBubble(
          this.range,
          this.bubble,
          this.playPauseVideo.currentTime,
          this.minVal
        );
      }

      // Work out how much of the media has played via the duration and currentTime parameters
      this.percentage = (100 / this.player.duration) * this.player.currentTime;
      // Update the progress bar's value
      if (!isNaN(this.percentage)) {
        this.progressBar.value = this.percentage;
        // Update the progress bar's text (for browsers that don't support the progress element)
        this.progressBar.innerHTML = this.percentage + "% played";
      }
    },
    seek(e) {
      const percent = e.offsetX / this.progressBar.offsetWidth;
      this.player.currentTime = percent * this.player.duration;
      e.target.value = percent / 100;
      e.target.innerHTML = this.progressBar.value + "% played";
    },
    forceRerender() {
      this.componentKey += 1;
    },
    moveQuestionUp(index) {
      if (this.questions.length > 1) {
        if (index !== 0) {
          let temp = this.questions[index - 1];
          this.questions[index - 1] = this.questions[index];
          this.questions[index] = temp;
          this.forceRerender();
        }
      }
      this.updateLocalStorage();
    },
    moveQuestionDown(index) {
      if (this.questions.length > 1) {
        if (index !== this.questions.length - 1) {
          let temp = this.questions[index + 1];
          this.questions[index + 1] = this.questions[index];
          this.questions[index] = temp;
          this.forceRerender();
        }
      }
      this.updateLocalStorage();
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
      this.updateLocalStorage();
      this.forceRerender();
    },
    selectQuizType(type) {
      this.chevronListBox = true;
      this.skippable = type;
      
    },
    addQuestion() {
      if (this.chevronListBox) {
        this.questions.push(_.cloneDeep(this.question));
        this.updateLocalStorage();
      }
    },
    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    setBubble(range, bubble, maxValue, minValue) {
      const val = range ? range.value : 0;
      const min = range ? range.min : minValue;
      const max = range ? range.max : maxValue;
      const newVal = Number(((val - min) * 100) / (max - min));
      bubble.innerHTML = this.sToTime(val * 1000);
      bubble.style.left = `calc(${newVal}%)`;
      // bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    },
    sToTime(duration) {
      let seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    async publishQuiz(type) {
      try {
        if (this.errors.length) {
          throw new Error("Please resolve the errors first");
        }
        this.dataValidated = true;
        let body = {
          questions: this.questions,
          is_published: type,
          is_skippable: this.skippable,
        };
        let response = await quizService.saveQuestions(body);
        if (response) {
          if (response.data.status === "success") {
            if (response.data.data.needToCreateNewVersion) {
              this.PopUp = true;
            } else {
              if (
                this.session._id != this.previousQuizId &&
                this.previousQuizId
              ) {
                const updated_quiz_version = {
                  old_quiz_id: this.previousQuizId,
                  new_quiz_id: this.session._id,
                  time: parseInt(this.range.value),
                  percentage: (100 / this.player.duration) * this.range.value,
                };
                this.popup = true;
                this.is_published = true;
                let resp = await videoService.updateVideoPoints(
                  updated_quiz_version
                );
                if (resp) {
                  if (resp.data.success) {
                    this.$toast.success("Published Successfully");
                    this.$parent.backMethod();
                  }
                }
              } else {
                this.saveVideoPoints(type);
              }
            }
            
          }
        }
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async createNewVersion() {
      try {
        if (this.errors.length) {
          throw new Error("Please resolve the errors first");
        }
        let body = {
          questions: this.questions,
          is_published: true,
          is_skippable: this.skippable,
        };
        let response = await quizService.createNewVersion(body);
        if (response) {
          if (response.data.status === "success") {
            let oldSessionId;
            let session = response.data.data.session;
            oldSessionId = session._id;
            delete session._id;
            session.quiz_id = response.data.data.quiz._id;
            const sessionRes = await sessionService.createSession(session);
            if(sessionRes.data.data.session) {
              const newSession = sessionRes.data.data.session;

              const updated_quiz_version = {
                old_quiz_id: oldSessionId,
                new_quiz_id: newSession._id,
                time: parseInt(this.range.value),
                percentage: (100 / this.player.duration) * this.range.value,
              };
              let resp = await videoService.updateVideoPoints(
                updated_quiz_version
              );
              this.popup = false;
              this.is_published = true;
              this.$toast.success("Published Successfully");
              this.$parent.backMethod();
            }
            
          }
        }
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
  },
};
</script>

<style src="./create-popup-quiz.css" scoped></style>
